<template>
  <div>
    <!--    <el-page-header @back="goBack" content="商品列表"></el-page-header>-->
    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!--  卡片视图区  -->
    <el-card class="box-card">
      <!--搜索与添加区域-->
      <el-row :gutter="30">
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
          <el-input placeholder="请输入搜索内容" v-model="querInfo.g_name" clearable @clear="inputchange" @keyup.native.enter="inputchange">
            <el-button slot="append" icon="el-icon-search" @click="inputchange"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-table :data="goodslist" border stripe v-loading="loading">
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column label="商品名称" prop="g_name" show-overflow-tooltip></el-table-column>
        <el-table-column label="销量" prop="g_ficti" show-overflow-tooltip></el-table-column>
        <el-table-column label="商品总库存" prop="g_stock" show-overflow-tooltip></el-table-column>
        <el-table-column label="商品价格" prop="g_price" show-overflow-tooltip></el-table-column>
        <el-table-column label="成本价" prop="cost" show-overflow-tooltip></el-table-column>
        <el-table-column label="市场价" prop="ot_price" show-overflow-tooltip></el-table-column>
        <el-table-column label="单位" prop="gu_name" show-overflow-tooltip></el-table-column>
        <el-table-column label="质量等级" prop="ql_name" show-overflow-tooltip></el-table-column>
        <el-table-column label="商品分类名称" prop="c_name" show-overflow-tooltip></el-table-column>
        <el-table-column label="状态" width="70px">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.g_status" @change="goodsListeditatat({g_status:scope.row.g_status,g_id:scope.row.g_id})" ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="热卖" prop="ga_status" width="70px">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.is_hot" @change="goodsListeditatat({is_hot:scope.row.is_hot,g_id:scope.row.g_id})" :disabled="is_hot"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="促销" prop="ga_status" width="70px">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.is_benefit" @change="goodsListeditatat({is_benefit:scope.row.is_benefit,g_id:scope.row.g_id})" :disabled="is_benefit"></el-switch>
          </template>
        </el-table-column>
<!--        <el-table-column label="新品" prop="ga_status" width="70px">-->
<!--          <template slot-scope="scope">-->
<!--            <el-switch v-model="scope.row.is_new" @change="goodsListeditatat({is_new:scope.row.is_new,g_id:scope.row.g_id})" :disabled="is_new"></el-switch>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column label="精品" prop="ga_status" width="70px">-->
<!--          <template slot-scope="scope">-->
<!--            <el-switch v-model="scope.row.is_best" @change="goodsListeditatat({is_best:scope.row.is_best,g_id:scope.row.g_id})" :disabled="is_best"></el-switch>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column label="操作" width="80px">
          <template slot-scope="scope">
              <el-button type="primary" size="mini" icon="el-icon-edit" @click="goodslistdetails(scope.row.g_id)" ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="querInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="querInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //分页
      querInfo: {
        page: 1,
        limit: 10,
        g_name:'',
      },
      total:0,
      loading:true,
      goodslist:[],
      //热卖
      is_hot:false,
      //优惠
      is_benefit:false,
      //新品
      is_new:false,
      //精品
      is_best:false,

    }
  },
  created() {
    this.getGoodsList()
  },
  methods: {
    //获取权限接口
    async getGoodsJurisdiction(){
      this.loading=true
      let str = window.sessionStorage.getItem('activePath');
      let activePath = str.split("/").join("");
      const { data : res } = await this.$http.get('menu/getMenuChildrenList', {params:{m_path : activePath}})
      if (res.code!=200)return  this.$message.error(res.msg)
      this.CateJurisdiction=res.data
      this.CateJurisdiction.forEach((item)=>{
        if (item.m_path=='add'){
          this.is_add=true
        }else if (item.m_path=='edit'){
          this.is_edit=true
        }else if(item.m_path=='del'){
          this.is_del=true
        }else if(item.m_path=='import'){
          this.is_import=true
        }else if(item.m_path=='export'){
          this.is_export=true
        }else if(item.m_path=='goods/dategoods'){
          this.is_goods_dategoods=true
        }else if(item.m_path=='editStatus'){
          this.is_editStatus=false
        }
      })
    },
    //获取商品
    async getGoodsList(){
      this.loading=true
      const {data: res} = await this.$http.get('goods/getGoodsList',
          {params: this.querInfo})
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // res.data.data.forEach(irem=>{
      //   if (item.ga_status==1)
      // })
      // 把数据列表 赋值给catelist
      this.goodslist = res.data.data
      //把数据赋值给total
      this.total = res.data.total
      this.loading=false
    },
    //监听pagesize改变
    handleSizeChange(newSize) {
      this.querInfo.limit = newSize
      this.getGoodsList()
    },
    //监听pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.page = newPage
      this.getGoodsList()
    },
    inputchange(){
      this.querInfo.page=1
      this.getGoodsList()
    },
    //修改状态
    async goodsListeditatat(row){
      const {data: res} = await this.$http.post(`goods/editGoods`,{
        g_id:row.g_id,
        is_hot:row.is_hot,
        is_benefit:row.is_benefit,
        is_new:row.is_new,
        is_best:row.is_best,
        g_status:row.g_status
      })
      if (res.code != 200) {
        row.ga_status = !row.ga_status
        return this.$message.error('更新状态失败')
      }
      this.$message.success('更新状态成功')
      this.getGoodsList()
    },
    goodslistdetails(id){
      this.$router.push({path: "/goodslist/goodslistdetails", query: {id: id}})
    },
  }
}
</script>

<style lang="less" scoped>
.el-col{
  margin-bottom: 20px;
}
</style>
